import { API, graphqlOperation } from 'aws-amplify';
import { createPost, deletePost, updatePost } from './graphql/mutations';
import { listPosts } from './graphql/queries';

export const deletePostDb = (deleteInput) => {
    async function deleteItem() {
      await API.graphql(graphqlOperation(deletePost, { input: deleteInput }));
    }
    deleteItem();
    return "Item deleted";
}

export const updatePostDb = (updateInput) => {
    async function updateItem() {
      await API.graphql(graphqlOperation(updatePost, { input: updateInput }));
    }
    updateItem();
    return "Item updated";
}

export const createPostDb = (createInput) => {
    async function createItem() {
        await API.graphql(graphqlOperation(createPost, { input: createInput }));
    }
    createItem();
    return "Item created";
} 

export const listPostsDb = (filterInput) => {
    async function listItems() {
        const allItems = await API.graphql(graphqlOperation(listPosts, {filter: filterInput}));
        return allItems;
    }
    return listItems();

}