import React from 'react'
import { NavLink } from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../UserContext";

export default function User() {
    const userContext = useContext(UserContext);
    if (userContext.getUsername() != null) {
        return (
            <>
            <h1>Hi {userContext.getUsername()}</h1>
            </>
        )
    }
    else {
        return (
            <NavLink className="header-link" to="/login">Login Here</NavLink>
        )
    }
}
