import React from 'react'
import './styles/AppContainer.css'

export default function AppContainer(props) {
  return (
    <div className='appContainer'>
        {props.children}
    </div>
  )
}
