import React from 'react'
import {NavLink} from "react-router-dom";
import './styles/HeaderNav.css'
import LoginLogout from './LoginLogout';

export default function HeaderNav() {
  return (
    <nav className='header-navbar'>
        <ul className='header-list'>
          <LoginLogout/>
          <NavLink className="header-link" to="/user">User</NavLink>
        </ul>
    </nav>
  )
}
