import React, {useState, useEffect} from 'react'
import Post from '../components/Post'
import PostWrapper from '../components/PostWrapper'
import './styles/Home.css'
import { API, graphqlOperation } from 'aws-amplify';
import {listPosts} from '../graphql/queries'
import { updatePost } from '../graphql/mutations';
import {useLocation, Navigate} from 'react-router-dom';
import { UserContext } from '../UserContext';
import {useContext } from "react";

export default function Home() {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    async function fetchPosts() {
      const result = await API.graphql(graphqlOperation(listPosts, {filter:{published:{eq:true}}})).catch(err => console.log(err));
      if (!result) return
      setPosts(result.data.listPosts.items)
    }
    fetchPosts()
  },[])

  useEffect(() => {
    const publishPosts = async (postList)  => {
      postList = postList.map(post => {delete post.updatedAt; return post})
      await Promise.all(postList.map(post => API.graphql(graphqlOperation(updatePost, {input: {id:post.id, voteCount:post.voteCount}}))))
    }
    publishPosts(posts)
  }, [posts])
  
  let location = useLocation()
  if (location?.state === "reload") {
    window.location.reload()
    return (<Navigate to="/" />)
  }

  const getPrompt = () => {
    return "this is the prompt"
  }
  
  const prompt = getPrompt()

  return (
    <div className='homeContainer'>
      <div className='dailyPrompt'>
        {prompt}
      </div>
      <div className='posts'>    
        {[...posts].sort((a,b) => b.voteCount - a.voteCount).map((post) => {
          return (
          <PostWrapper key={post.id} id={post.id} votes={post.voteCount} setPosts={setPosts}>
            <Post key={post.id} id={post.id} date={post.createdAt} title={post.title} content={post.content} />
          </PostWrapper>    
          )})}
      </div>
    </div>
  )
}
