import React, {useState} from 'react'
import './styles/Alert.css'

export default function Alert({message, confirm, cancel}) {
    const handleClose = (e) => {
        e.preventDefault();
        confirm()
    };
    const handleCancel = (e) => {
        e.preventDefault();
        cancel()
    };
    return (
        <>
        <div className='blur'>{}</div>
        <div className='alert'>
            <div className='alertText'>
            {message}
            </div>
            <div className='alertButtons'>
             <button className='button' onClick={handleClose}>Yes</button>
             <button className='button' onClick={handleCancel}>No</button>
            </div>
        </div>
        </>
 
    )
}
