let touchstartX = 0
let touchendX = 0
let touchstartY = 0
let touchendY = 0
   
function createCheckDirection(setCallback) {
    return function checkDirection() {
        if (touchstartY > 450) {
            if (touchendY - touchstartY < -20) {
                setCallback("up")
            } else if (touchendY - touchstartY > 20) {
                setCallback("down") 
            }
        }
    }
}

let checkDirection = () => {};

const handleStart = (e) => {
    touchstartX = e.changedTouches[0].screenX
    touchstartY = e.changedTouches[0].screenY
}

const handleEnd = (e) => { 
    touchendX = e.changedTouches[0].screenX
    touchendY = e.changedTouches[0].screenY
    checkDirection()
}  

export function addTouchListener(setCallback) {
    checkDirection = createCheckDirection(setCallback)
    document.addEventListener('touchstart', handleStart)
    document.addEventListener('touchend', handleEnd)
}

export function removeTouchListener() {
    document.removeEventListener('touchstart', handleStart)
    document.removeEventListener('touchend', handleEnd)
}