import React, {useState, useMemo} from 'react'
import './styles/Post.css'

export default function Post({votes, published, selected, date, title, content, options, id}) {
    const [post, setPost] = useState({date: date, title: title, content: content}) 
    const [swiping, setSwiping] = useState("notSwiping")
    if (options === undefined) {
        return (
            <div className='postContainer noOpts'>
                 <div className='postHeader noOpts'>
                <div className='postTitle'>
                    {`${post.title}`}
                </div>
                <div className='postInfo'>
                    {`${post.date}`}
                </div>
            </div>
            <div className='postContent'>{post.content}</div>
        
            </div>
        )
    }
    const {deletePost, publishPost} = options

    let startX = 0
    let endX = 0

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX
        localStorage.setItem("touchStart", startX)
    }
    const handleTouchEnd = (e) => {
        setSwiping("notSwiping")
        endX = e.changedTouches[0].clientX
        if (endX - startX < -100) {
            deletePost(id)
        }
        if (endX - startX > 100) {
            publishPost(id)
        }
    }
    const handleTouchMove = (e) => {
        endX = e.changedTouches[0].clientX
        startX = localStorage.getItem("touchStart")

        console.log(endX - startX)
        if (endX - startX > 50) {
            setSwiping("right")
        }
        if (endX - startX < -50) {
            setSwiping("left")
        }
    }
        

    return (
       
        <div className={'postContainer ' + 'is'+selected.toString() + " " + swiping} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove}>
            <div className='postHeader' >
                <div className='postTitle' >
                    {`${post.title}`}
                </div>
                <div className='postInfo'>
                    {`${post.date}`}
                </div>
                <div className='postOptions' id={id}>
                    <button className='deleteButton button' id={id} onClick={deletePost}>delete</button>
                    <button className='publishButton button' id={id} onClick={publishPost}>{published ? "unpublish" : "publish"}</button>
                    <div >{votes}</div>
                </div>
            </div>
            <div className='postContent'>{post.content}</div>
        </div>
    )
}
