import './App.css';
import HeaderContainer from './containers/HeaderContainer';
import BodyContainer from './containers/BodyContainer';
import NavbarContainer from './containers/NavbarContainer';
import AppContainer from './containers/AppContainer';
import Prompts from './routes/Prompts'
import Write from './routes/Write'
import Home from './routes/Home'
import User from './routes/User'
import Login from './routes/Login'
import {Route,Routes} from "react-router-dom";
import { Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import Navbar from './components/Navbar';
import {useContext, useEffect} from 'react'
import {UserContext} from './UserContext'
Amplify.configure(awsExports);
  
function App() { 
  const userContext = useContext(UserContext);
  useEffect(()=> {
    userContext.getUserData()
  }, [])
  return (
      <AppContainer>
        <HeaderContainer>
        </HeaderContainer>
        <BodyContainer>
          <Routes>
            <Route path="/" element={<Home></Home>}/>
            <Route path="/login" element={<Login></Login>}/>
            <Route path="/prompts" element={<Prompts></Prompts>}/>
            <Route path="/write" element={<Write></Write>}/>
            <Route path="/user" element={<User></User>}/>
            <Route path="*" element={<Home></Home>}/>
          </Routes>
        </BodyContainer>

        <NavbarContainer>
          <Navbar></Navbar>
        </NavbarContainer>
      </AppContainer>
  );
}
export default App;