import React from 'react'
import {Link} from 'react-router-dom'
import './styles/LoginLogout.css'
import {useContext, useState, useEffect} from 'react'
import {UserContext} from '../UserContext'
import {Auth} from 'aws-amplify'
import {Navigate} from 'react-router-dom'

export default function LoginLogout() {
    const [showLogout, setShowLogout] = useState()
    const userContext = useContext(UserContext)
    const amplifyLogout = async () => {
        await Auth.signOut()
    }
    const handleLogout = () => {
        userContext.clearUserData()
        amplifyLogout()
        console.log("loggin out")
        setShowLogout(false)
    }
    console.log(showLogout)
    if (showLogout === true) {
        return (
            <button className='logoutButton' onClick={handleLogout}>
                Logout
            </button>
        )
    } else {
        return (
            <Link to="/login" replace> Login </Link>  
        )
    }
}
