var UserProfile = (function() {
    var user = JSON.parse(sessionStorage.getItem("user"));
    var username = sessionStorage.getItem("username");
    var userEmail = sessionStorage.getItem("userEmail");

    var getUserData = function() {
        return {user:getUser(), username:getUsername(), userEmail:getUserEmail()}
    };

    var setUser = function(_user) {
        user = _user;
    };

    var setUsername = function(_username) {
        username = _username;
    };

    var setUserEmail = function(_userEmail) {
        userEmail = _userEmail;
    };

    var setUserData = function(userData) {
        user = userData.user
        username = userData.username
        userEmail = userData.userEmail
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("userEmail", userEmail);
    };

    var getUsername = function() {
        if (username == "") {
            username = sessionStorage.getItem("username");
        }
        return username
    };

    var getUserEmail = function() {
        if (userEmail == "") {
            userEmail = sessionStorage.getItem("userEmail");
        }
        return userEmail
    };

    var getUser = function() {
        if (user == {}) {
            user = JSON.parse(sessionStorage.getItem("user"));
        }
        return user
    };

    var clearUserData = function() {
        user = {}
        username = null
        userEmail = null
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("userEmail", userEmail);
    }


    return {
        setUser: setUser,
        setUsername: setUsername,
        setUserEmail: setUserEmail,
        setUserData: setUserData,
        getUser: getUser,
        getUsername: getUsername,
        getUserEmail: getUserEmail,
        getUserData: getUserData,
        clearUserData: clearUserData,
    }

})();

export default UserProfile;