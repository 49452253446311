import React from 'react'
import './styles/NavbarContainer.css'

export default function NavbarContainer(props) {
  return (
    <nav className="navbarContainer">
      {props.children}
    </nav>
  )
}
