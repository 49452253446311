import React from 'react'
import './styles/HeaderContainer.css'
import HeaderNav from '../components/HeaderNav';
import HeaderTitle from '../components/HeaderTitle';

export default function HeaderContainer({props}) {
  return (
    <div className="headerContainer">
        <HeaderTitle className="headerText"></HeaderTitle>
        <div></div>
        <HeaderNav props={props} className="headerNav"></HeaderNav>
    </div>
  )
}
