import { withAuthenticator } from '@aws-amplify/ui-react'
import {Auth} from 'aws-amplify'
import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useContext} from "react";
import {UserContext} from "../UserContext";
import '@aws-amplify/ui-react';

function Login() {
    const userContext = useContext(UserContext);
    const getUser = async () => {
        let user = await Auth.currentUserInfo()
        let username = user.username
        let userEmail = user.attributes.email
        let userData = {
            user: user,
            username: username,
            userEmail: userEmail
        }
        console.log("setting user data", userData)
        userContext.setUserData(userData)
        return userData
    }  
    getUser()
    let location = useLocation()
    if (location?.state) {
        console.log(location.state)
    }
    return (
        <Navigate to="/" />
    )
}

export default withAuthenticator(Login)