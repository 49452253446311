import React from 'react'
import './styles/Navbar.css'
import {
    NavLink,
} from "react-router-dom";

export default function Navbar() {
  return (
    <ul className="navbar-list">
        <NavLink className="nav-link " to="/">Home</NavLink>
        <NavLink className="nav-link " to="/write">Write</NavLink>
        <NavLink className="nav-link " to="/prompts">Prompts</NavLink>
    </ul>
  )
}
