import React from 'react'
import './styles/PostWrapper.css'

export default function PostWrapper(props) {
  const {id, votes, setPosts} = props

  const handleUpvote = () => {
    setPosts(posts => {
      const newPosts = [...posts]
      const index = newPosts.findIndex(post => post.id === id)
      newPosts[index].voteCount++
      return newPosts
    }
    )
  }
  const handleDownvote = () => {
    setPosts(posts => {
      const newPosts = [...posts]
      const index = newPosts.findIndex(post => post.id === id)
      newPosts[index].voteCount--
      return newPosts
    } 
    )
  }


  return (
    <div className='postWrapper'>
      <div className='voteCount'>{votes}</div>
      <div className='votes'>
        <button className='upvote' onClick={handleUpvote}>:)</button>
        <button className='downvote' onClick={handleDownvote}>:(</button>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  )
}
