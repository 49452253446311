import React, {useEffect, useState, useLayoutEffect} from 'react'
import Post from '../components/Post'
import WriteInput from '../components/WriteInput'
import { v4 as uuidv4 } from 'uuid';
import './styles/Write.css'
import Alert from '../components/Alert';
import { UserContext } from '../UserContext';
import {useContext } from "react";
import {createPostDb, deletePostDb, updatePostDb, listPostsDb} from '../dbFunctions';
import { addTouchListener, removeTouchListener } from '../touchListener';

export default function Write() {
  const userContext = useContext(UserContext);
  const [posts, setPosts] = useState([])
  const [verify, setVerify] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [swipeState, setSwipeState] = useState("up")

  useEffect(() => {
    if (userContext.getUsername() === null) return
    const posts = sessionStorage.getItem(`wroteRead_posts_${userContext.getUsername()}`)
    if (posts) {
      setPosts(JSON.parse(posts))
    }
    if (!posts) {
      const filter = {user:{eq:userContext.getUsername()}}
      const result = listPostsDb(filter)
      console.log(result)
      if (typeof result === "Object") {
        setPosts(result.data.listPosts.items)
        sessionStorage.setItem(`wroteRead_posts_${userContext.getUsername()}`, JSON.stringify(result.data.listPosts.items))
      } 
    }
    addTouchListener(setSwipeState)

    const alertUser = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", alertUser);
    return () => {
      removeTouchListener()
      window.removeEventListener("beforeunload", alertUser);
    }
  },[])

  // const observer = new IntersectionObserver(entries => {
  //   entries.forEach(entry => {
  //     if (entry.isIntersecting) {
  //       // entry.target.classList.toggle('istrue')
  //     } else {
  //       return
  //     }
  //   }, {
  //     threshold: [0.5]
  //   })
  // })

  // useLayoutEffect(() => {
  //   const postElements = document.querySelectorAll(".postContainer")
  //   if (!postElements.length > 0) return
  //   postElements.forEach(postElement => {
  //     observer.observe(postElement)
  //   })
  // })

  if (userContext.getUsername() === null) return <Alert message="You must be logged in to write a post" />

  const submitText = (text, title) => {
    const newPost = {
      owner: userContext.getUsername(),
      id: uuidv4(),
      user: userContext.getUsername(),
      content: text,
      title: title,
      createdAt: new Date().toLocaleString(),
      published: false,
      voteCount: 0,
    }
    createPostDb(newPost)
    const newPosts = [...posts, newPost]
    sessionStorage.setItem(`wroteRead_posts_${userContext.getUsername()}`, JSON.stringify(newPosts))
    setPosts(newPosts)
  }

  const verifyDelete = (event) => {
    if (typeof event === "string") {
      setDeleteId(event)
    } else {
      setDeleteId(event.target.id)
    }
    setVerify(true)
  }

  const deletePostFromDb = () => {
    const deleteInput = {id: deleteId};
    deletePostDb(deleteInput)
    const newPosts = [...posts].filter(post => post.id !== deleteId)
    sessionStorage.setItem(`wroteRead_posts_${userContext.getUsername()}`, JSON.stringify(newPosts))
    setPosts(newPosts)
    setVerify(false)
  }

  const updatePosts = (post) => {
    const newPosts = [...posts].map(p => {
      if (p.id === post.id) {
        return post
      } else {
        return p
      }})
    sessionStorage.setItem(`wroteRead_posts_${userContext.getUsername()}`, JSON.stringify(newPosts))
    setPosts(newPosts)
  }

  const togglePublish = (event) => {
    let id = event
    if (typeof event === "object") {
      id = event.target.id
    }
    let postToPublish = [...posts].filter(post => post.id === id)[0]
    delete postToPublish.updatedAt
    postToPublish.published = !postToPublish.published
    const input = {id: postToPublish.id, published: postToPublish.published}
    updatePostDb(input)
    updatePosts(postToPublish)
  }


  const toggleSwipe = () => {
    setSwipeState(swipeState === "up" ? "down" : "up")
  }

  return (
    <div className={`writeContainer ${swipeState}`}>
      <div className='writeTitle'>Posts!</div>
      <div className={`writePosts ${swipeState}`}>    
        {[...posts].sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)).map((post) => {
          return(
            <>
            <Post key={post.id} className="post" votes={post.voteCount} selected={(post.id === deleteId)}  id={post.id} date={post.createdAt} published={post.published} title={post.title} content={post.content} options={{deletePost:verifyDelete,publishPost:togglePublish}}/>
            {verify && post.id === deleteId ? <Alert  message='Are you sure you want to delete this post?' confirm={deletePostFromDb} cancel={() => {setVerify(false); setDeleteId('')}}/> : null}
            </>
          )
        })}
      </div>
      <div className={`writeInput ${swipeState}`}>
        <WriteInput submitText={submitText} toggleSwipe={toggleSwipe}/>
      </div>
    </div>
  )
}
