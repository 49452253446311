import React, {useState} from 'react'
import './styles/WriteInput.css'

export default function WriteInput({submitText, toggleSwipe}) {
  const [text, setText] = useState('')
  const [title, setTitle]  = useState('')

  const handleChange = (event) => {
      event.preventDefault()
      setText(event.target.value)
  }
  const handleTitle = (event) => {
      event.preventDefault()
      setTitle(event.target.value)
  }

  const handleSubmit = () => {
    if (text.length > 0) {
      document.getElementById('post-text').value = ''
      document.getElementById('post-title').value = ''
      submitText(text, title)
      setText('')
      setTitle('')
    }
  }

  return (
    <div className='writeInputContainer'>
      {/* <button className='swipeTab' onClick={toggleSwipe}></button> */}
      <div className='writeInputHeader'>
        <input className='writeInputTitle' autoComplete="off" type="text" id='post-title' placeholder="title" onChange={e => handleTitle(e)}></input>
        <button className='writeInputButton button' onClick={handleSubmit}>submit</button>
      </div>
      <textarea  className='writeInputText' id='post-text' type="text" onChange={e => handleChange(e)}></textarea>
    </div>
  )
}
