/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      user
      id
      content
      title
      createdAt
      published
      voteCount
      owner
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user
        id
        content
        title
        createdAt
        published
        voteCount
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
